<template>
    <img v-if="displayImage" :src="source" class="file-image">

    <app-form
        v-else
        :value="file"
        :schema="getSchema('Files')"
        :filter="filter"
        :is-readonly="true"
        :hide-delete="true"
        :hide-cancel="true"
        :hide-title="true" />
</template>

<script>
import axios from 'axios'

export default {

    components: {
        appForm: () => import('@/components/AppForm')
    },
    props: {
        value: {
            type: String,
            default: null
        },
        filter: {
            type: Object,
            default: null
        },
        isHover: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            source: '',
            file: {}
        }
    },
    computed: {
        displayImage() {
            return this.source !== '';
        }
    },

    watch: {
        value: {
            immediate: true,
            async handler() {
                let file = (await axios.get('/api/Files/' + this.value)).data;
                if (this.isEmpty(file)) {
                    this.source = '';
                    return;
                }

                if (file.isImage) {
                    this.source = `/api/Files/GetFile/${this.value}`;
                }
                else {
                    this.file = file.file;
                    this.source = '';
                }
            }
        }
    }
};
</script>
<style scoped>
    .file-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
</style>
